<template>
  <div id="app">
    <div class="home-theme-wrapper theme-color1">
      <router-view />
    </div>
  </div>
</template>

<script>
import HelloWorld from "./components/HelloWorld.vue";

export default {
  name: "App",
  components: {
    HelloWorld,
  },
};
</script>

<style lang="less">
@btnbg: #2979ff;
@combg: #303e70;
@label: #9fb0ff;
@import "./assets/iconfont/iconfont.css";
@import "./assets/css/public.less";



.van-toast .van-toast__loading {
    color: #FFFFFF!important;
}

.van-popover--light .van-popover__content {
    background-color: #f5f5f5!important;
    color: #000;
}

.van-popover--light .van-popover__action {
  width: 1.8rem;
  padding: 0!important;
}

/* .van-toast {
	width: 100px !important;
} */


</style>
