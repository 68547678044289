import Vue from "vue";
// 用于创建路由器
import VueRouter from "vue-router";
import store from '@/store'

import { routes } from "./router";
import { isMobile, isPcPath } from "../assets/js/tools"
import { Toast } from "vant";

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

// 创建一个路由
const router = new VueRouter({
    routes,
});

router.beforeEach((to, from, next) => {
    if (isMobile()) {
        // if (isPcPath(to.path) + '' !== '-1') {
        //     next({ path: '/index' })
        //     return;
        // }
        if (to.meta.isAuth) {// 判断是否需要登录
            console.log("需要登录")
            if (store.state.token) {// 已经登录
                console.log("进来了=================")
                if (!store.state.user.id) {
                    store.dispatch('GetInfo').then(res => { // 拉取user_info
                        // 动态路由，拉取菜单
                        store.commit('HASLOGIN');
                    }).catch((err) => {
                        console.log(err)
                        console.log("获取用户信息失败")
                        Toast("获取用户信息失败");
                        store.dispatch('LogOut').then(() => {
                            location.reload() // 为了重新实例化vue-router对象 避免bug
                        })
                    })
                }
                next();
            } else {// 未登录
                next({path: '/login'});
            }
        } else {
            if (store.state.token) {// 已经登录
                console.log("进来了=================")
                if (!store.state.user.id) {
                    store.dispatch('GetInfo').then(res => { // 拉取user_info
                        // 动态路由，拉取菜单
                        store.commit('HASLOGIN');
                    }).catch((err) => {
                        console.log(err)
                        console.log("获取用户信息失败")
                        Toast("获取用户信息失败");
                        store.dispatch('LogOut').then(() => {
                            location.reload() // 为了重新实例化vue-router对象 避免bug
                        })
                    })
                }
            }
            next();
        }
    } else {
        if (to.path === '/index') {// 去首页 随便去
            if (store.state.token) {
                if (!store.state.user.id) {
                    store.dispatch('GetInfo').then(res => { // 拉取user_info
                        // 动态路由，拉取菜单
                        store.commit('HASLOGIN');
                    }).catch((err) => {
                        console.log(err)
                        console.log("获取用户信息失败")
                        store.dispatch('LogOut').then(() => {
                            location.reload() // 为了重新实例化vue-router对象 避免bug
                        })
                    })
                }
            }
            next()
        } else if (to.path === '/register') {
            next()
        } else {
            if (!store.state.hasLogin && !store.state.token) {// 验证是否登录过了
                store.commit('SHOWlOGINDIALOG');
                next({ path: '/index' });
                return;
            } else if (store.state.token) {
                if (!store.state.user.id) {
                    store.dispatch('GetInfo').then(res => { // 拉取user_info
                        // 动态路由，拉取菜单
                        console.log("获取用户信息成功")
                        store.commit('HASLOGIN');
                    }).catch((err) => {
                        console.log("获取用户信息失败")
                        store.dispatch('LogOut').then(() => {
                            location.reload() // 为了重新实例化vue-router对象 避免bug
                        })
                    })
                }
            }
            next();
        }
    }
});


export default router;
