<!-- cny -->
<template>
	<div id="LotteryHall">
		<!-- 顶部导航 -->
		<van-nav-bar :title="$t('common.CNY')" left-arrow @click-left="onClickLeft" @click-right="onClickRight" />
		<!-- 信息 -->
		<div class="balanceBox">
			<div class="balance">
				<!-- <p class="money">{{ user.money | privatefg }} {{$t('common.CNY')}}</p> -->
				<p class="money">{{ user.money | privateMoney }} {{$t('common.CNY')}}</p>
				<p class="name">{{$t('profile.zhhb')}}</p>
			</div>
			<div class="rechargeBtn">
				<span class="iconfont icon-zhangshangchongzhi"></span>
				{{$t('m_tabbar.deposit')}}
			</div>
		</div>
		<!-- 选项卡 -->
		<div class="hideNavScroll">
			<div class="navWrap">
				<nav class="nav">
					<div v-for="(item, index) in productList" :key="index" :class="{ active: activeIndex == index }">
						<span @click="tabIndexChange(item, index)">{{
              item.show_name
            }}</span>
					</div>
				</nav>
			</div>
		</div>
		<!-- 内容 -->
		<ul class="hallImg">
			<li @click="jumpDetile(item,index)" v-for="(item, index) in productList[activeIndex].list" :key="index">
				<img v-if="item.type == 0" src="../../../assets/new_img/vip1.png" alt="" />
				<img v-if="item.type == 1" src="../../../assets/new_img/vip2.png" alt="" />
				<img v-if="item.type == 2" src="../../../assets/new_img/vip3.png" alt="" />
				<img v-if="item.type == 3" src="../../../assets/new_img/vip4.png" alt="" />

				<div class="cont">
					<p class="peopleNumber" v-if="$i18n.locale === 'zh'">
						<i class="iconfont iconren"></i>交易人数{{item.people}}人
					</p>
					<p class="peopleNumber" v-else>
						<i class="iconfont iconren"></i>{{$t('trading.jyrs')}}{{item.people}}
					</p>
					<p class="name">
						{{ item.name }}<span>({{ item.shuoming }})</span>
					</p>
					<!-- <p class="tips">{{$t('trading.dbzd')}}{{ item.min | privatefg }}</p>
					<p class="tips">{{$t('trading.jrzdje')}}{{ item.minmoney | privatefg }}</p> -->
					<p class="tips">{{$t('trading.dbzd')}}{{ item.min | privateMoney }}</p>
					<p class="tips">{{$t('trading.jrzdje')}}{{ item.minmoney | privateMoney }}</p>
				</div>
			</li>
			<!-- <li>
        <img src="../../../assets/img/vip2.png" alt="" />
        <div class="cont">
          <p class="peopleNumber">
            <i class="iconfont iconren"></i>交易人数9470人
          </p>
          <p class="name">中级<span>(INTERMEDIATE)</span></p>
          <p class="tips">单笔最低买入1.00</p>
          <p class="tips">进入最低金额1,000.00</p>
        </div>
      </li>
      <li>
        <img src="../../../assets/img/vip3.png" alt="" />
        <div class="cont">
          <p class="peopleNumber">
            <i class="iconfont iconren"></i>交易人数8899人
          </p>
          <p class="name">高级<span>(ADVANCED)</span></p>
          <p class="tips">单笔最低买入1.00</p>
          <p class="tips">进入最低金额3,000.00</p>
        </div>
      </li>
      <li>
        <img src="../../../assets/img/vip4.png" alt="" />
        <div class="cont">
          <p class="peopleNumber">
            <i class="iconfont iconren"></i>交易人数8568人
          </p>
          <p class="name">贵宾<span>(VIP Room)</span></p>
          <p class="tips">单笔最低买入10.00</p>
          <p class="tips">进入最低金额3,000.00</p>
        </div>
      </li> -->
		</ul>
		<van-dialog v-model="show" :title="$t('m_login.qsrmm')" :confirm-button-text="$t('m_history.qd')" :cancel-button-text="$t('m_history.qx')" @confirm="yzPassword" show-cancel-button class="vand">
			<input type="text" v-model="pwd" class="vaninput" />
		</van-dialog>
	</div>
</template>

<script>
	import {
		productShow,
		getchpwd
	} from "@/api/investhome";
	import {
		mapState
	} from "vuex";
	import {
		Dialog,
		Toast
	} from "vant";
	export default {
		computed: {
			...mapState(["hasLogin", "user"])
		},
		name: "mcny",
		data() {
			return {
				show: false,
				activeIndex: 0,
				productList: [],
				pwd: '',
				activeItem: null
			};
		},
		//生命周期 - 创建完成（访问当前this实例）
		created() {},
		//生命周期 - 挂载完成（访问DOM元素）
		mounted() {
			this.productShowFun();
		},
		methods: {
			onClickLeft() {
				this.$router.go(-1)
			},
			onClickRight() {
				Toast("按钮");
			},
			/* 选项卡切换 */
			tabIndexChange(row, index) {
				this.activeIndex = index;
			},
			/* 验证密码 */
			yzPassword() {
				getchpwd({
					id: this.activeItem.id,
					password: this.pwd
				}).then(res => {
					if (res.code + '' === '200') {
						let query = {
							detile: this.activeItem.id,
							product: this.productList[this.activeIndex].id,
							type: this.activeItem.type,
							//title: this.productList[this.activeIndex].show_name,
							title: item.name,
						};
						this.$router.push({
							path: '/trading',
							query
						});
					} else {
						this.pwd = '';
						this.isshow();
						console.log("密码错误");
					}
				})
			},
			/* 跳转到详情 */
			jumpDetile(item) {
				console.log("===========item" + item.name)
				console.log("===========当前余额" + this.user.money)
				console.log("===========最低进入金额" + item.minmoney)
				// console.log(this.user.money < item.minmoney);
				if (parseInt(this.user.money) < parseInt(item.minmoney)) {
					// console.log(11111)
					Dialog.confirm({
						title: this.$t("common.msg"),
						message: this.$t("m_mcny.tip"),
						confirmButtonText: this.$t("m_mcny.qcz"),
						cancelButtonText: this.$t("m_mcny.qx"),
					}).then(() => {
						this.$router.push({
							path: '/recharge'
						});
					});
				} else if (item.is_password == 1) {
					this.isshow()
					this.activeItem = item;
					/* this.yzPassword(item.id, this.pwd); */
				} else {
					// console.log(2222)
					let query = {
						detile: item.id,
						product: this.productList[this.activeIndex].id,
						type: item.type,
						//title: this.productList[this.activeIndex].show_name,
						title: item.name,
					};
					this.$router.push({
						path: '/trading',
						query
					});
				}
			},
			isshow() {
				this.show = true
			},
			/* 查询产品 */
			productShowFun() {
				productShow().then((res) => {
					console.log(res);
					this.productList = res.data;
				});
			},
		},
	};
</script>
<style scoped lang="less">
	@import "../../../assets/css/mobile.less";

	.vand {
		color: #000 !important;
	}

	.vaninput {
		color: #000;
		width: 80%;
		height: 30px;
		margin: 10px 28px;
	}

	#LotteryHall {
		width: 100%;
		min-height: 100%;
		background: #303e70 url(../../../assets/new_img/my_bg.png) no-repeat top;
		background-size: 100% auto;
		background-position: top;
		color: #fff;
		overflow: hidden;
	}

	#LotteryHall .balanceBox {
		display: flex;
		justify-content: space-between;
		padding-left: 0.3rem;
		padding-top: 0.2rem;
		padding-bottom: 0.2rem;

		.balance .money {
			color: #9fb0ff;
			font-size: 0.48rem;
			line-height: 0.6rem;
		}

		.balance .name {
			color: #fff;
			font-size: 0.3rem;
			line-height: 0.4rem;
		}

		.rechargeBtn {
			height: 0.7rem;
			padding: 0 0.15rem;
			border-radius: 0.35rem 0 0 0.35rem;
			/* background: linear-gradient(0deg, #ceab77 0%, #eddbc1 100%); */
			background-image: linear-gradient(90deg, #2948ff, #396afc);
			font-size: 0.3rem;
			line-height: 0.7rem;
			color: #ffffff;
			display: flex;
			align-items: center;

			.iconfont {
				color: #ffffff;
				line-height: 0.7rem;
				font-size: 0.4rem;
				margin-right: 0.1rem;
			}
		}
	}

	#LotteryHall .hideNavScroll {
		height: 1.2rem;
		overflow: hidden;

		.navWrap {
			width: 100%;
			overflow-y: hidden;
			overflow-x: scroll;

			.nav {
				width: 100%;
				height: 1rem;
				display: flex;
				align-items: center;
				margin-top: 0.21rem;
				margin-bottom: 0.15rem;

				div {
					flex: 1;
					text-align: center;
					white-space: nowrap;
					position: relative;

					span {
						display: inline-block;
						padding: 0.15rem 0.3rem;
						text-align: center;
						font-size: 0.32rem;
						line-height: 0.5rem;
						color: #fff;
					}
				}

				.active span {
					border-bottom: 2px solid #2979ff;
				}

				div::after {
					content: "";
					position: absolute;
					right: 0;
					top: 50%;
					width: 2px;
					height: 0.4rem;
					transform: translateY(-50%);
					background-color: #eecb77;
				}
			}
		}
	}

	#LotteryHall .hallImg {
		padding: 0.3rem;

		li {
			width: 100%;
			height: 100%;
			margin-bottom: 0.3rem;
			position: relative;

			img {
				width: 100%;
				height: 3.1rem;
				object-fit: cover;
			}

			.cont {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				padding: 0.3rem;
				color: #fff;
				box-sizing: border-box;

				.peopleNumber {
					font-size: 0.24rem;
					line-height: 0.4rem;
					text-align: right;

					i {
						font-size: 0.3rem;
						margin-right: 0.15rem;
					}
				}

				.name {
					font-size: 0.6rem;
					font-family: Myanmar;
					line-height: 1rem;

					span {
						font-size: 0.3rem;
						line-height: 1rem;
						margin-left: 0.15rem;
						font-weight: 600;
					}
				}

				.tips {
					font-size: 0.24rem;
					line-height: 0.5rem;
				}
			}
		}
	}
</style>
