import Vue from 'vue'
import VueI18n from 'vue-i18n'
import LangStorage from './lang'
import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import viLocale from 'element-ui/lib/locale/lang/vi'
import thLocale from 'element-ui/lib/locale/lang/th'

Vue.use(VueI18n)


const DEFAULT_LANG = 'th'
const LOCALE_KEY = 'localeLanguage'
const locales = {
    zh: Object.assign(require('./zh.json'), zhLocale),
    english: Object.assign(require('./en.json'), enLocale),
    vietnamese: Object.assign(require('./vietnamese.json'), viLocale),
    th: Object.assign(require('./th.json'), thLocale)
}
const i18n = new VueI18n({
    locale: LangStorage.getLang('th'),
    messages: locales
})
export const setup = lang => {
    if (lang === undefined) {
        lang = window.localStorage.getItem(LOCALE_KEY)
        if (locales[lang] === undefined) {
            lang = DEFAULT_LANG
        }
    }
    console.log(lang)
    window.localStorage.setItem(LOCALE_KEY, lang)
    Object.keys(locales).forEach(lang => {
        document.body.classList.remove(`lang-${lang}`)
    })
    let bLang = lang;
    if(lang === 'english') {
        bLang = 'en';
    }
    console.log(lang)
    document.body.classList.add(`lang-${bLang}`)
    document.body.setAttribute('lang', bLang)
    Vue.config.lang = lang
    i18n.locale = lang
}
window.i18n = i18n
export default i18n